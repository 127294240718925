import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import Outcome from './outcome';

const Slider = ({ title, sportId, outcomes }) => (
  outcomes && outcomes.length > 0
    && (
    <>
      <h3 className="bvs-h4 upsell-header bvs-header">{ title }</h3>
      <div className="upsell-most-popular-wrapper">
        <Carousel
          embedded
          scrollStep={234}
          className={`upsell-cards-slider length-${outcomes.length}`}
        >
          {
            outcomes.map((outcome) => (
              <Outcome
                id={outcome.id}
                key={outcome.id}
                sportId={sportId}
                clickSource="upsell-widget"
              />
            ))
          }
        </Carousel>
      </div>
    </>
    )
);

Slider.propTypes = {
  title: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
};

export default Slider;

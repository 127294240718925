import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export default ({ timePeriod }) => (
  getJSON('/api/sportsbook/sports/364/tree', {
    date: timePeriod,
    c: locale(),
  })
    .catch(() => [])
);

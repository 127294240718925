import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Spinner } from 'bv-components';
import withLazyLoad from 'bv-lazy-load';

const MediaStreaming = withLazyLoad(<Spinner big={false} />)('mediaStreaming');
const HORSE_RACING_ID = '200';

const Streaming = ({ hasStream, eventId }) => {
  const button = useRef(null);

  useEffect(() => {
    let root;
    if (hasStream) {
      root = createRoot(button.current);
      root.render(
        <MediaStreaming
          sportId={HORSE_RACING_ID}
          eventId={eventId}
          playerNode={() => document.getElementsByClassName('js-before-player-stream')[0]}
        />,
      );
    }

    return (() => {
      if (root) {
        root.unmount();
      }
    });
  }, [hasStream]);

  return <div ref={button} />;
};

Streaming.propTypes = {
  eventId: PropTypes.number.isRequired,
  hasStream: PropTypes.bool.isRequired,
};

export default Streaming;

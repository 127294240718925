import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'underscore';
import { t } from 'bv-i18n';
import { seo } from 'bv-services';
import { Redux as EssentialScoresRedux } from 'sportsbook-essential-scores';
import { useFetchEvent } from 'sportsbook-event-hooks';
import Markets from 'sportsbook-markets-v2';
import fetchScoreboard from 'SportsbookComponents/scoreboard/services/api';
import Head from 'Seo/components/head';
import EventComponents from '../../sportsbook_components/event_components';
import DayEventsSelectorContainer from './day_events_selector/day_events_selector_container';
import StreamingContainer from './streaming/streaming_container';
import RaceInfoContainer from './race_info/race_info_container';
import MeetingEvents from './meeting_events';
import EventNotFound from './event_not_found';
import JsonLd from './json_ld';

const HORSE_RACING_SPORT_ID = 200;

const Racecard = ({ eventId, meetingId, sportId }) => {
  const [event, fetching] = useFetchEvent(eventId);
  const getEventScoreboard = useMemo(EssentialScoresRedux.selectors.makeGetEventScoreboard, []);
  const scoreboard = useSelector((state) => getEventScoreboard(state, { eventId }), isEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetching || !event) return;

    seo.setMetaInfo(() => Promise.resolve({
      data: {
        meeting_name: event.meetingName,
        description: event.description,
        sport_name: t('javascript.horse_racing'),
      },
      type: 'pre_event',
      id: sportId,
    }));
  }, [event, fetching]);

  useEffect(() => {
    fetchScoreboard({ eventId }).then((response) => {
      dispatch(EssentialScoresRedux.actionCreators.upsertEssentialScores([{
        ...response.essential_scoreboard,
        comments: response.comments,
      }]),
      );
    });

    return () => dispatch(EssentialScoresRedux.actionCreators.removeEssentialScores([eventId]),
    );
  }, [eventId]);

  return (
    <>
      {event && (
        <Head>
          <JsonLd event={event} />
        </Head>
      )}
      <div className="horseracing-racecard-header">
        <DayEventsSelectorContainer eventId={eventId} />
        <StreamingContainer eventId={eventId} />
      </div>
      <MeetingEvents meetingId={meetingId} eventId={eventId} />
      <RaceInfoContainer eventId={eventId} />
      {!fetching && !event && <EventNotFound />}
      <EventComponents
        eventId={eventId}
        meetingId={meetingId}
        sportId={HORSE_RACING_SPORT_ID}
        key={`event-components-${eventId}`}
      />
      {!isEmpty(scoreboard) && <Markets key={`markets-${eventId}`} eventId={eventId} sportId={sportId} periodKey={scoreboard.dsc} picker />}
    </>
  );
};

Racecard.propTypes = {
  sportId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
};

export default Racecard;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useToggle } from 'bv-hooks';
import { getMeetingName } from 'HorseRacingRacecard/selectors';
import DayEventsSelector from './day_events_selector';

const DayEventsSelectorContainer = ({ title, eventId }) => {
  const [toggled, toggleToggled] = useToggle(true);

  return (
    <div className="navbar">
      <span className="navbar__toggle bvs-link" onClick={toggleToggled} />
      <h1 className="bvs-h4">{title}</h1>
      {toggled || (
        <DayEventsSelector
          eventId={eventId}
          onEventClick={toggleToggled}
        />
      )}
    </div>
  );
};

DayEventsSelectorContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  title: getMeetingName(state, ownProps),
});

export default connect(mapStateToProps)(DayEventsSelectorContainer);

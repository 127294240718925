import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { pluck } from 'underscore';
import { useDispatch } from 'react-redux';
import { addOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import Slider from './slider';

const SliderContainer = ({ outcomes, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addOutcomes(outcomes));

    return () => dispatch(removeOutcomes(pluck(outcomes, 'id')));
  }, [outcomes]);

  return <Slider outcomes={outcomes} {...rest} />;
};

SliderContainer.propTypes = {
  outcomes: PropTypes.instanceOf(Array).isRequired,
  addOutcomes: PropTypes.func.isRequired,
  removeOutcomes: PropTypes.func.isRequired,
};

export default SliderContainer;

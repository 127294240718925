import { createSelector } from 'reselect';
import { property, wrap } from 'underscore';
import { makeGetEvent } from 'sportsbook-events-selector';
import { getMarkets } from 'sportsbook-markets-selector';
import { getOutcomes } from 'sportsbook-outcomes-selector';

// Function that will receive an object and will return a given property value or a default one
const propertyOrDefault = (propKey, defaultValue) => (
  wrap(property(propKey), (func, obj) => func(obj) || defaultValue)
);

const getOutcomeId = (_, props) => props.outcomeId;

// Use only 1 selector as only 1 racecard is shown at the same time in the page
// Better memoisation having into account the use case of this
const getEvent = makeGetEvent();

const getEventPropSelector = (propFunc) => createSelector([getEvent], propFunc);

export const getTimePeriod = getEventPropSelector(propertyOrDefault('timePeriod', 'today'));
export const getMeetingName = getEventPropSelector(propertyOrDefault('meetingName', ''));
export const getHasStream = getEventPropSelector(propertyOrDefault('hasStream', false));
export const getTimeForm = getEventPropSelector(property('timeForm'));
export const getPressAssociation = getEventPropSelector(property('PAData'));

export const makeGetEWTerms = () => createSelector(
  [getOutcomeId, getOutcomes, getMarkets],
  (outcomeId, outcomes, markets) => {
    const outcome = outcomes[outcomeId];

    if (!outcome) return null;

    const market = markets[outcome.mId];

    return market ? market.pltDes : '';
  },
);

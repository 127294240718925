import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeGetOutcomesByIds } from 'sportsbook-outcomes-selector';
import PropTypes from 'prop-types';
import { omit } from 'underscore';
import { RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';
import PressAssociation from './press_association';
import TimeformDetail from './timeform_detail/timeform_detail';

const PressAssociationTimeform = ({ pressAssociation, timeForm }) => {
  const [displayDetail, setDisplayDetail] = useState(false);
  const toggleDetail = () => setDisplayDetail(!displayDetail);
  const outcomes = useSelector((state) => (
    makeGetOutcomesByIds()(state, {
      outcomes: timeForm.runners ? Object.keys(timeForm.runners) : [],
    })
  ));
  const runnerCount = outcomes ? outcomes.filter((o) => o && o.enabled).length : 0;

  return (
    <div>
      <div className="racecard-tf-info-wrapper" onClick={toggleDetail}>
        <span className="racecard-tf-logo" />
        <PressAssociation raceName={timeForm.raceTitleShort} runnerCount={runnerCount} {...omit(pressAssociation, 'raceName')} />
        { /* This will probably be an image once the styling is done */ }
        <div className="racecard-tf-link">{t('javascript.race_overview.see_race_info')}</div>
      </div>
      { /* ------------------------------------------------------- */ }
      {
        displayDetail && (
          <RenderInBody>
            <TimeformDetail
              pressAssociation={pressAssociation}
              timeForm={timeForm}
              closeCallback={toggleDetail}
            />
          </RenderInBody>
        )
      }
    </div>
  );
};

PressAssociationTimeform.propTypes = {
  timeForm: PropTypes.instanceOf(Object).isRequired,
  pressAssociation: PropTypes.instanceOf(Object).isRequired,
};

export default PressAssociationTimeform;

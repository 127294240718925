import { useParams } from 'react-router-dom';
import { ContentLayout } from 'bv-components';

import Racecard from './racecard';

const App = () => {
  const { eventId, meetingId } = useParams();

  return (
    <ContentLayout>
      <div className="horseracing-racecard">
        <Racecard
          eventId={parseInt(eventId, 10)}
          meetingId={parseInt(meetingId, 10)}
          sportId={364}
        />
      </div>
    </ContentLayout>
  );
};

App.displayName = 'HorseRacingRacecardApp';

export default App;

import { getJSON } from 'bv-fetch';
import { locale as l } from 'bv-i18n';
import { playerInfoRegexp, updateCommentsWithPlayerComment } from '../helpers/comments_helper';

const emptyResponse = () => ({
  essential_scoreboard: {
    disabledScoreboard: true,
  },
  comments: [],
  stats: {},
  timeline: {},
});

const present = (response) => {
  if (response.comments && response.comments.length > 0) {
    response.comments = response.comments.map((c) => ({ ...c, timer: null }));
  }

  const { comments } = response;
  if (comments) {
    comments.forEach((playerComment) => {
      if (playerInfoRegexp.test(playerComment.messageType)) {
        updateCommentsWithPlayerComment(comments, playerComment);
      }
    });
    response.comments = comments.filter((comment) => !playerInfoRegexp.test(comment.messageType));
  }
  return response;
};

export const commentsFilter = (comments) => {
  const filteredComments = [];
  let nextMessageType = null;
  let nextComment = null;
  let currentMessageType = null;

  comments.forEach((comment, i) => {
    currentMessageType = String(comment.messageType).toLowerCase();
    nextComment = comments[i + 1] || { messageType: '', datetime: 0 };
    nextMessageType = String(nextComment.messageType).toLowerCase();

    if (!(currentMessageType === 'point' && (nextMessageType === 'double_fault' || nextMessageType === 'ace'))) {
      filteredComments.push(comment);
    } else if (comment.datetime - nextComment.datetime > 5000) {
      filteredComments.push(comment);
    }
  });

  return filteredComments;
};

export default ({ eventId, locale = l() }) => getJSON('/bv_api/scoreboards.json', { event_id: eventId, locale })
  .catch(emptyResponse)
  .then(present);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFetching } from 'bv-hocs';
import { Spinner, RenderInFullscreen } from 'bv-components';
import { t } from 'bv-i18n';

import fetchEventsTree from 'HorseRacingRacecard/api/fetch_events_tree';
import { getTimePeriod } from 'HorseRacingRacecard/selectors';
import { EventsTreePicker } from 'sportsbook-components';

const DayEventsSelector = ({
  onEventClick,
  eventId,
  dataFetched,
  tree,
}) => (
  <RenderInFullscreen title={t('javascript.all_races')} onCloseClick={onEventClick}>
    {
      dataFetched
        ? (
          <EventsTreePicker
            eventId={eventId}
            tree={tree}
            onEventClick={onEventClick}
          />
        ) : <Spinner />
    }
  </RenderInFullscreen>
);

DayEventsSelector.propTypes = {
  eventId: PropTypes.number.isRequired,
  tree: PropTypes.instanceOf(Array).isRequired,
  dataFetched: PropTypes.bool.isRequired,
  onEventClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  timePeriod: getTimePeriod(state, props),
});

export default connect(mapStateToProps)(
  withFetching(
    fetchEventsTree,
    'tree',
    { forceRender: true },
  )(DayEventsSelector),
);

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFeature } from 'bv-hocs';
import {
  getTimeForm,
  getPressAssociation,
} from 'HorseRacingRacecard/selectors';
import RaceInfo from './race_info';

const RaceInfoContainer = ({ timeForm, pressAssociation, featureEnabled }) => (
  <RaceInfo
    timeForm={featureEnabled ? timeForm : null}
    pressAssociation={pressAssociation}
  />
);

RaceInfoContainer.propTypes = {
  featureEnabled: PropTypes.bool.isRequired,
  timeForm: PropTypes.instanceOf(Object),
  pressAssociation: PropTypes.instanceOf(Object),
};

RaceInfoContainer.defaultProps = {
  timeForm: null,
  pressAssociation: null,
};

const mapStateToProps = (state, ownProps) => ({
  timeForm: getTimeForm(state, ownProps),
  pressAssociation: getPressAssociation(state, ownProps),
});

export default withFeature('timeform')(connect(mapStateToProps)(RaceInfoContainer));

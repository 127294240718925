import { getJSON } from 'bv-fetch';

const url = (eventId) => `/bv_api/sportsbook_components/event_components/events/${eventId}/upsell_market.json`;
const emptyResponse = () => [];

export default ({
  eventId, marketDimension, periodDimension, lineDimension, numberOfOutcomes,
}) => getJSON(url(eventId), {
  md: marketDimension,
  pd: periodDimension,
  ld: lineDimension,
  n: numberOfOutcomes,
})
  .catch(emptyResponse);

import PropTypes from 'prop-types';

const JsonLd = ({
  event: {
    startTime,
    city,
    country,
    PAData: {
      raceName,
    },
  },
}) => {
  const startDate = new Date(startTime).toISOString();
  const endDate = startDate.split('T')[0];
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Event',
    description: raceName,
    startDate,
    endDate,
    location: {
      '@type': 'Place',
      address: {
        addressRegion: city,
        addressCountry: country,
      },
    },
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line risxss/catch-potential-xss-react
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredData),
      }}
    />
  );
};

JsonLd.propTypes = {
  event: PropTypes.shape({
    startTime: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    PAData: PropTypes.shape({
      raceName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default JsonLd;

import PropTypes from 'prop-types';
import { compose, property } from 'underscore';
import { useFetch } from 'bv-hooks';
import { EventsSlider } from 'sportsbook-components';

import fetchEventPathEvents from 'HorseRacingRacecard/api/fetch_event_path_events';

const findEvent = (events, eventId) => events.find((event) => event.id === eventId);
const getTimePeriod = compose(property('timePeriod'), findEvent);

const MeetingEventsContainer = ({ meetingId, eventId }) => {
  const [events] = useFetch(() => fetchEventPathEvents({ eventPathId: meetingId }), [meetingId]);
  if (!events) return null;

  const timePeriod = getTimePeriod(events, eventId);

  return (
    <EventsSlider
      events={!timePeriod ? events : events.filter((event) => event.timePeriod === timePeriod)}
      eventId={eventId}
    />
  );
};

MeetingEventsContainer.propTypes = {
  meetingId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default MeetingEventsContainer;

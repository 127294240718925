import PropTypes from 'prop-types';

const RaceComment = ({ comment }) => (
  <div className="racecard-tf-comment">{comment}</div>
);

RaceComment.propTypes = {
  comment: PropTypes.string.isRequired,
};

export default RaceComment;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isSmall } from 'bv-helpers/screen';
import GeneralInfoContainer from './general_info/general_info_container';
import RaceComment from './race_comment';
import ThreePlaces from './three_places';

const outcomeId = (threePlaces) => (threePlaces[0] ? threePlaces[0].id : null);

const TimeformDetail = ({ pressAssociation, timeForm, closeCallback }) => {
  // Side Effects regarding scrolling to current outcome and locking background scroll
  useEffect(() => {
    // lock / unlock the scroll behind the modal
    document.documentElement.classList.add('body-scroll-lock');
    document.body.classList.add('has-modal');
    return () => {
      document.documentElement.classList.remove('body-scroll-lock');
      document.body.classList.remove('has-modal');
    };
  }, []);

  return (
    <div
      className="racecard-tf-detail-wrapper modal_wrapper"
      {...(isSmall() ? { onClick: closeCallback } : {})}
    >
      <div className="bvs-card racecard-tf-detail-content">
        <div className="racecard-tf-detail-top-content">
          <div className="racecard-tf-detail-top-content-wrapper">
            <span className="racecard-tf-modal-logo" />
            <span className="close-icon" onClick={closeCallback}>
              <span className="racecard-tf-modal-close bvs-link bvs-icon is-close" />
            </span>
            <GeneralInfoContainer
              {...pressAssociation}
              eventId={timeForm.event_id}
              outcomeId={outcomeId(timeForm.threePlaces)}
            />
            <RaceComment comment={timeForm.race_comment} />
          </div>
        </div>
        <div className="racecard-tf-detail-places" onClick={closeCallback}>
          <ThreePlaces eventId={timeForm.event_id} threePlaces={timeForm.threePlaces} />
        </div>
      </div>
    </div>
  );
};

TimeformDetail.propTypes = {
  timeForm: PropTypes.instanceOf(Object).isRequired,
  pressAssociation: PropTypes.instanceOf(Object).isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default TimeformDetail;

import { FeatureGuardComponent } from 'bv-components';
import * as componentTypes from './component_types';

const ComponentFactory = (component) => {
  const {
    attributes, componentId, featureFlipperKey, type,
  } = component;
  const Component = componentTypes[type];

  if (Component) {
    if (!featureFlipperKey) return <Component componentId={componentId} {...attributes} />;

    return (
      <FeatureGuardComponent feature={featureFlipperKey}>
        <Component componentId={componentId} {...attributes} />
      </FeatureGuardComponent>
    );
  }

  return null;
};

export default ComponentFactory;

import PropTypes from 'prop-types';
import PressAssociationTimeform from './press_association_timeform';

const RaceInfo = ({ timeForm, pressAssociation }) => (
  timeForm
  && <PressAssociationTimeform pressAssociation={pressAssociation} timeForm={timeForm} />
) || null;

RaceInfo.propTypes = {
  timeForm: PropTypes.instanceOf(Object),
  pressAssociation: PropTypes.instanceOf(Object),
};

RaceInfo.defaultProps = {
  timeForm: null,
  pressAssociation: null,
};

export default RaceInfo;

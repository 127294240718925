import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { identity } from 'underscore';
import { t } from 'bv-i18n';
import RaceHeader from 'HorseRacingRacecard/components/race_header';
import eventInfoHelper from 'HorseRacingRacecard/helpers/event_info_helper';

const infoItemsMapping = [
  {
    prop: 'eventInfo',
    title: t('javascript.race_overview.distance'),
    helper: eventInfoHelper,
  },
  {
    prop: 'eWTerms',
    title: t('javascript.race_overview.ew'),
    helper: identity,
  },
  {
    prop: 'going',
    title: t('javascript.race_overview.ground'),
    helper: identity,
  },
];

const InfoItem = ({ title, value }) => (
  <div className="racecard-tf-infoitem">
    <div className="racecard-tf-infoitem-title">{title}</div>
    { value && <div className="racecard-tf-infoitem-value" dangerouslySetInnerHTML={{ __html: sanitize(value) }} /> }
  </div>
);

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const GeneralInfo = ({ eventId, ...props }) => (
  <div>
    <div className="racecard-tf-infoitem-wrapper">
      {
        infoItemsMapping
          .filter((infoItem) => !!props[infoItem.prop])
          .map((infoItem) => (
            <InfoItem
              title={infoItem.title}
              value={infoItem.helper(props[infoItem.prop])}
            />
          ))
          .reduce((acc, divItem) => (acc === null ? [divItem] : [...acc, divItem]), null)
      }
    </div>
    <RaceHeader
      className="racecard-tf-racename"
      eventId={eventId}
    />
  </div>
);

GeneralInfo.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default GeneralInfo;

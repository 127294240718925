import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { useSelector } from 'react-redux';
import { getPressAssociation, getTimeForm } from 'HorseRacingRacecard/selectors';

const RaceHeader = ({
  className,
  eventId,
}) => {
  const pressAssociation = useSelector((state) => getPressAssociation(state, { eventId }));
  const timeForm = useSelector((state) => getTimeForm(state, { eventId }));

  const { raceName } = pressAssociation || {};
  const { raceTitleShort, raceClass } = timeForm || {};

  const nameText = raceTitleShort || raceName;
  if (!nameText) return null;

  const classText = raceClass ? ` - ${t('javascript.race_class')} ${raceClass}` : '';

  return (
    <div className={className}>
      {`${nameText}${classText}`}
    </div>
  );
};

RaceHeader.propTypes = {
  className: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default RaceHeader;

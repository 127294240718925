export const commentMinute = (comment) => (comment && comment.clk ? comment.clk.replace(/:\d+$/, '') : '');
export const commentToContainerClass = (comment, event) => {
  if (!comment.opp) {
    return 'scb-comment';
  }
  if (comment.opp === event.opponent_a_id) {
    return 'scb-home-comment';
  }
  return 'scb-away-comment';
};

export const playerInfoRegexp = /\w+_player$/i;
export const updateCommentsWithPlayerComment = (comments, playerComment) => {
  comments.forEach((comment) => {
    if (comment.feedCode === playerComment.feedCode) {
      comment.player = playerComment.player;
    }
  });
};

export default null;
/* eslint no-param-reassign:"off" */

import PropTypes from 'prop-types';
import { Runner } from 'sportsbook-components';

const ThreePlaces = ({ eventId, threePlaces }) => (
  <div className="racecard-outcomes">
    { threePlaces.map((runner) => <Runner eventId={eventId} {...runner} />) }
  </div>
);

ThreePlaces.propTypes = {
  eventId: PropTypes.number.isRequired,
  threePlaces: PropTypes.instanceOf(Array).isRequired,
};

export default ThreePlaces;

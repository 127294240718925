/* eslint no-unused-vars: off */

import { connect } from 'react-redux';
import { makeGetEWTerms } from 'HorseRacingRacecard/selectors';
import GeneralInfo from './general_info';

const makeMapStateToProps = () => {
  const getEWTerms = makeGetEWTerms();

  return (state, ownProps) => ({
    eWTerms: getEWTerms(state, ownProps),
  });
};

export default connect(makeMapStateToProps)(GeneralInfo);

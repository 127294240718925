/* eslint no-unused-vars: off */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHasStream } from 'HorseRacingRacecard/selectors';
import Streaming from './streaming';

const mapStateToProps = (state, ownProps) => ({
  hasStream: getHasStream(state, ownProps),
});

const StreamingContainer = connect(mapStateToProps)(Streaming);

StreamingContainer.propTypes = {
  hasStream: PropTypes.bool.isRequired,
};

export default StreamingContainer;

import PropTypes from 'prop-types';
import { compact } from 'underscore';
import { sanitize } from 'dompurify';
import eventInfoHelper from 'HorseRacingRacecard/helpers/event_info_helper';
import { t } from 'bv-i18n';

// We will filter out all falsey values for the additional info and display just
// those which are present
const additionalInfo = ({ eventInfo, going }) => compact([eventInfoHelper(eventInfo), going]).join(' | ');

// ------------------------------------------------------------------------

const PressAssociation = ({ raceName, runnerCount, ...pressAssociation }) => (
  <div className="racecard-pa-info">
    <div className="racecard-pa-additional-info" dangerouslySetInnerHTML={{ __html: sanitize(additionalInfo(pressAssociation)) }} />
    { runnerCount && <div className="racecard-pa-additional-info">{t('javascript.race_overview.runner_count', { count: runnerCount })}</div> }
  </div>
);

PressAssociation.propTypes = {
  raceName: PropTypes.string.isRequired,
  runnerCount: PropTypes.number.isRequired,
  pressAssociation: PropTypes.shape({
    eventInfo: PropTypes.string,
    going: PropTypes.string,
  }).isRequired,
};

export default PressAssociation;

import { t } from 'bv-i18n';

const EventNotFound = () => (
  <div className="no-events-message">
    <div className="no-events-message__icon" />
    <div className="no-events-message__text">
      <p>{t('empty_list')}</p>
    </div>
  </div>
);

export default EventNotFound;

import PropTypes from 'prop-types';

import { withFetching } from 'bv-hocs';
import fetchEventComponents from './api';
import componentFactory from './component_factory';

const EventComponents = ({
  sportId, meetingId, eventId, components,
}) => components
  .map((comp) => ({
    ...comp,
    attributes: {
      ...comp.attributes, sportId, meetingId, eventId,
    },
  }))
  .map(componentFactory);

EventComponents.propTypes = {
  sportId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  components: PropTypes.instanceOf(Array),
};

EventComponents.defaultProps = {
  components: [],
};

export default withFetching(fetchEventComponents, 'components')(EventComponents);
